<template>
  <lazy-component class="hotelSelectRoomDiv">
    <div class="hotelSelectRoomTopDiv">
      <div class="hotelSelectRoomTopMainDiv">
        <div class="hotelSelectRoomTopImgDiv">
          <div class="hotelSelectRoomTopImgParent">
            <div class="hotelSelectRoomTopImgViewerDiv">
              <image-viewer
                :showBack="true"
                v-if="
                  hotelImageListAll != undefined && hotelImageListAll.length > 0
                "
                class="hotelSelectRoomTopImgViewer"
                :imageData="hotelImageListAll"
                @showImageViewer="showImageViewer"
              ></image-viewer>
              <div v-else class="hotelSelectRoomTopImgViewerElseDiv">
                <img
                  class="hotelSelectRoomTopImgViewerElseImg"
                  :src="'../images/logo.gif'"
                  alt=""
                  width="100%"
                  height="100%"
                />
              </div>
            </div>

            <!-- <a-modal
                            v-model="visibleImageViewer"
                            title=""
                            :footer="null"
                            :width="'auto'"
                            :dialog-style="{ 'display': 'flex', 'align-items': 'center', 'max-width': '1080px', 'top': '0', 'height': '100%'}"
                        >
                            <image-previewer v-if="visibleImageViewer" :imageData="hotelImageListAll" :imageIndex="indexImageViewer" @close="showImageViewer(false)"/>
                        </a-modal>
                        
                        <a-modal
                            v-model="visibleMapViewer"
                            title=""
                            :footer="null"
                            :width="'auto'"
                            :dialog-style="{ 'display': 'flex', 'align-items': 'center', 'max-width': '1080px', 'top': '0', 'height': '100%'}"
                        >
                            <img v-if="visibleMapViewer" class="hotelMapViewImg"  width="100%" height="100%"
                                :src="hotelMapListAll.length > 0 && hotelMapListAll[0] != ''? `${hotelMapListAll[0]}`:''" draggable="false">
                            
                        </a-modal> -->
          </div>
        </div>

        <a-modal
          class="hotelSelectRoomModel"
          v-model="visibleImageViewer"
          title=""
          :footer="null"
          :width="'auto'"
          :dialog-style="{
            display: 'flex',
            'align-items': 'center',
            'max-width': '1080px',
            top: '0',
            height: '100%',
          }"
        >
          <image-previewer
            v-if="visibleImageViewer"
            :imageData="hotelImageListAll"
            :imageIndex="indexImageViewer"
            @close="showImageViewer(false)"
          />
        </a-modal>

        <div class="hotelSelectRoomTopSubDiv">
          <div class="hotelSelectRoomTopSubChildDiv">
            <div class="hotelSelectRoomTopSubContent">
              <div class="hotelSelectRoomTopSubContent1">
                <div class="hotelSelectRoomTopSubInfoDiv">
                  <div class="hotelSelectRoomTopSubLogoDiv">
                    <img
                      class="imageHotelLogo"
                      :src="
                        selectedHotel.hotellogo != undefined
                          ? `${selectedHotel.hotellogo}`
                          : '../images/logo.gif'
                      "
                    />
                  </div>
                  <div class="hotelSelectRoomTopSubContent2">
                    <div class="hotelDetailName font-new">
                      {{ selectedHotel.hotelname }}
                    </div>
                    <div class="hotelDetailSubName font-new">
                      {{
                        selectedHotel.hotelproperty != undefined &&
                        selectedHotel.hotelproperty.location != undefined
                          ? selectedHotel.hotelproperty.location
                          : "Raa Atol, Collection"
                      }}
                    </div>
                    <div class="hotelSelectRoomTopSubStartDiv">
                      <StarRating
                        :max-rating="6"
                        :rating="selectedHotel.star"
                        :show-rating="false"
                        :round-start-rating="false"
                        v-bind:star-size="18"
                        :active-on-click="true"
                        :read-only="true"
                        active-color="#FFC400"
                        inactive-color="#E7E7E7"
                      />
                      <a-popover placement="rightTop" class="StarOverDiv">
                        <template slot="content">
                          <div class="resortStarOverDivCell font-new">
                            Stars are on a 6-point scale indicating the level of
                            luxury based on service, amenities, facilities &
                            price. 6-stars indicate the highest level of luxury.
                          </div>
                        </template>
                        <img
                          class="hotelSelectRoomTopSubStarIcon"
                          src="../../assets/images/starInfo.png"
                          alt=""
                        />
                      </a-popover>
                    </div>
                  </div>
                </div>
                <div class="hotelSelectRoomTopSubBtnDiv">
                  <div
                    class="hotelFactBtnSelect font-new"
                    @click="gotoFactsheet"
                  >
                    Fact sheet
                  </div>
                  <div
                    class="hotelMapBtnSelect font-new"
                    @click="gotoResortMap(true)"
                  >
                    Resort map
                  </div>
                </div>
              </div>
              <div class="hotelSelectRoomTopSubContent3">
                <div class="hotelDetailPropertyTitle font-new">
                  Property Highlights
                </div>
                <div class="highlightsLine"></div>
                <div class="hotelDetailPropertyMainDiv">
                  <div class="hotelDetailPropertyRowDiv">
                    <div class="hotelDetailPropertyColDiv">
                      <div class="hotelDetailPropertyColCellDiv">
                        <img
                          class="highlightsIcons"
                          src="../../assets/images/icon_map.png"
                          alt=""
                        />
                        <div style="margin-left: 14px">
                          <div class="hotelSevicesItemTitle font-new">
                            How to get here
                          </div>
                          <div class="hotelSevicesItemDetail font-new">
                            {{
                              selectedHotel.hotelproperty == undefined
                                ? "45 minutes seaplane"
                                : selectedHotel.hotelproperty.getHere
                            }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="hotelDetailPropertyColDiv">
                      <div class="hotelDetailPropertyColCellDiv">
                        <img
                          class="highlightsIcons"
                          src="../../assets/images/icon_cooks.png"
                          alt=""
                        />
                        <div style="margin-left: 14px">
                          <div class="hotelSevicesItemTitle font-new">
                            Dining and lounges
                          </div>
                          <div class="hotelSevicesItemDetail font-new">
                            {{
                              selectedHotel.hotelproperty == undefined
                                ? "11"
                                : selectedHotel.hotelproperty.diningLounges
                            }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="hotelDetailPropertyRowDiv">
                    <div class="hotelDetailPropertyColDiv">
                      <div class="hotelDetailPropertyColCellDiv">
                        <img
                          class="highlightsIcons"
                          src="../../assets/images/icon_hand.png"
                          alt=""
                        />
                        <div style="margin-left: 14px">
                          <div class="hotelSevicesItemTitle font-new">
                            Best for
                          </div>
                          <div class="hotelSevicesItemDetail font-new">
                            {{
                              selectedHotel.hotelproperty == undefined
                                ? "22 dining and 4 bars"
                                : selectedHotel.hotelproperty.bestFor
                            }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="hotelDetailPropertyColDiv">
                      <div class="hotelDetailPropertyColCellDiv">
                        <img
                          class="highlightsIcons"
                          src="../../assets/images/icon_homes.png"
                          alt=""
                        />
                        <div style="margin-left: 14px">
                          <div class="hotelSevicesItemTitle font-new">
                            Resort size
                          </div>
                          <div class="hotelSevicesItemDetail font-new">
                            {{
                              selectedHotel.hotelproperty == undefined
                                ? selectedHotel.hoteldatarooms != undefined
                                  ? selectedHotel.hoteldatarooms.numberofroom
                                  : ""
                                : selectedHotel.hotelproperty.resortSize
                            }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <a-modal
          v-model="visibleMapViewer"
          title=""
          :footer="null"
          :width="'auto'"
          :dialog-style="{
            display: 'flex',
            'align-items': 'center',
            'max-width': '1080px',
            top: '0',
            height: '100%',
          }"
        >
          <!-- <image-previewer v-if="visibleMapViewer" :imageData="hotelImageListAll" :imageIndex="indexImageViewer" @close="showImageViewer(false)"/> -->
          <img
            v-if="visibleMapViewer"
            class="hotelMapViewImg"
            :src="
              hotelMapListAll.length > 0 && hotelMapListAll[0] != ''
                ? `${hotelMapListAll[0]}`
                : ''
            "
            draggable="false"
          />
        </a-modal>
      </div>
    </div>

    <div class="hotelSelectRoomTabsDiv">
      <div class="resortTextDiv">
        <a-tabs default-active-key="1">
          <a-tab-pane key="1" tab="Overview">
            <div class="hotelSelectRoomTabCellDiv">
              <span v-html="selectedHotel.hotelOverview"></span>
            </div>
          </a-tab-pane>
          <a-tab-pane key="2" tab="RESORT AMENITIES">
            <div class="hotelSelectRoomTabCellDiv">
              <span v-html="selectedHotel.hotelAmenities"></span>
            </div>
          </a-tab-pane>
          <a-tab-pane key="3" tab="VUE TIP">
            <div class="hotelSelectRoomTabCellDiv">
              <span v-html="selectedHotel.hotelVueTip"></span>
            </div>
          </a-tab-pane>
          <a-tab-pane key="4" tab="CHILD POLICY">
            <div class="hotelSelectRoomTabCellDiv">
              <span v-html="selectedHotel.hotelChildPolicy"></span>
            </div>
          </a-tab-pane>
        </a-tabs>
      </div>

      <!-- <div v-if="false" style="margin-left:4px;margin-right:4px;border: 1px solid #E7E7E7;margin-top:67px">
                <div style="margin-left: auto;margin-right: auto;max-width:1200px;">
                    <div style="width:100%;margin-top:20px;padding-left:20px;padding-right:20px;">
                        <span v-html="selectedHotel.hoteltext"></span>
                    </div>
                </div>
            </div> -->
    </div>

    <div class="hotelSelectRoomListDiv">
      <div class="hotelSelectRoomListMainDiv">
        <div class="hotelRoomTableTopName">Room type:</div>
        <a-list item-layout="horizontal" :data-source="roomInfoList">
          <a-list-item slot="renderItem" slot-scope="item, index">
            <div class="hotelSelectRoomListItemDiv">
              <div class="hotelSelectRoomListItemMainDiv">
                <div class="hotelSelectRoomListItemBackDiv">
                  <div class="hotelSelectRoomListItemBackground"></div>
                </div>
                <div class="roomListCellCarouselDiv">
                  <VueSlickCarousel
                    v-if="item.roominfo.gallaryimages.length > 0"
                    style="padding: 0; margin: 0"
                    :dots="false"
                    :infinite="true"
                    :speed="500"
                    :slidesToShow="1"
                    :slidesToScroll="1"
                    @afterChange="afterPageChange($event, index)"
                    :initialSlide="sliderStartIndex[index]"
                    :autoplay="false"
                    :arrows="false"
                  >
                    <div
                      v-for="(_image, iImage) in item.roominfo.gallaryimages"
                      :key="_image"
                    >
                      <img
                        class="sliderImageDetailSelect"
                        :src="_image != '' ? `${_image}` : ''"
                        draggable="false"
                        @click="
                          showRoomImageViewer(
                            true,
                            iImage,
                            item.roominfo.gallaryimages
                          )
                        "
                      />
                    </div>
                  </VueSlickCarousel>
                  <div v-else>
                    <img
                      class="sliderImageDetailSelect"
                      style="object-fit: none"
                      :src="'../images/logo.gif'"
                      draggable="false"
                    />
                  </div>

                  <div class="roomImagePrev">
                    <a-icon
                      class="nextBtnBackground"
                      type="left"
                      :style="{ fontSize: '40px', color: 'white' }"
                      @click="
                        prevSlide(index, item.roominfo.gallaryimages.length)
                      "
                    />
                  </div>
                  <div class="roomImageNext">
                    <a-icon
                      class="nextBtnBackground"
                      type="right"
                      :style="{ fontSize: '40px', color: 'white' }"
                      @click="
                        nextSlide(index, item.roominfo.gallaryimages.length)
                      "
                    />
                  </div>

                  <div class="roomImageSearchIconDiv">
                    <img
                      class="roomImageSearchIcon"
                      src="../../assets/images/magnifying_glass.png"
                      alt=""
                      width="39px"
                    />
                  </div>
                </div>
                <div class="hotelSelectRoomListItemContentDiv">
                  <div>
                    <div class="hotelRoomTableName font-new">
                      {{ item.roominfo.roomname }}
                      <font
                        class="hotelRoomTableViewPlanBtn"
                        @click="showFloorPlan(index, false, item)"
                        >(view floor plan)</font
                      ><br />
                    </div>
                    <div>
                      <div
                        class="hotelSelectRoomListItemDetailText"
                        v-bind:style="[
                          isShowRoomTextMore[index]
                            ? {}
                            : { '-webkit-line-clamp': '3', 'line-clamp': '3' },
                        ]"
                      >
                        <span v-html="item.roominfo.roomtext"></span>
                      </div>
                      <div
                        class="hotelRoomTableMoreBtn"
                        @click="showRoomTextMore(item.roominfo.roomtext)"
                      >
                        +more
                      </div>
                      <!-- <div v-if="isShowRoomTextMore[index]" style="color:var(--primary-button-color);cursor:pointer;font-size: 13px;" @click="showRoomTextMore(index, false)">-hide</div> -->
                    </div>
                    <div style="width: 100%">
                      <div class="roomCardtypeDetailCellDiv">
                        <div class="roomCardtypeDetailCellImgDiv">
                          <img
                            class="roomItemIcon"
                            src="../../assets/images/room_size_icon.png"
                          />
                        </div>
                        <div class="roomCardtypeDetailCellContentDiv">
                          <div class="roomItemTitle">Room Size:</div>
                          <div class="roomItemDetail" style="width: 50%">
                            {{ item.roominfo.roomSize }}
                          </div>
                        </div>
                      </div>
                      <div class="roomCardtypeDetailCellDiv">
                        <div class="roomCardtypeDetailCellImgDiv">
                          <img
                            class="roomItemIcon"
                            src="../../assets/images/standard_icon.png"
                          />
                        </div>
                        <div class="roomCardtypeDetailCellContentDiv">
                          <div class="roomItemTitle">Standard Guests:</div>
                          <div class="roomItemDetail" style="width: 50%">
                            {{ item.roominfo.standardOccupancy }}
                          </div>
                        </div>
                      </div>
                      <div class="roomCardtypeDetailCellDiv">
                        <div class="roomCardtypeDetailCellImgDiv">
                          <img
                            class="roomItemIcon"
                            src="../../assets/images/max_peaple_icon.png"
                          />
                        </div>
                        <div class="roomCardtypeDetailCellContentDiv">
                          <div class="roomItemTitle">Max Guests:</div>
                          <div class="roomItemDetail" style="width: 50%">
                            {{ item.roominfo.maxOccupancy }}
                          </div>
                        </div>
                      </div>
                      <div class="roomCardtypeDetailCellDiv">
                        <div class="roomCardtypeDetailCellImgDiv">
                          <img
                            class="roomItemIcon"
                            src="../../assets/images/bedding_icon.png"
                          />
                        </div>
                        <div class="roomCardtypeDetailCellContentDiv">
                          <div class="roomItemTitle">Bedding:</div>
                          <div class="roomItemDetail" style="width: 50%">
                            {{ item.roominfo.bedding }}
                          </div>
                        </div>
                      </div>
                      <div class="roomCardtypeDetailCellDiv">
                        <div class="roomCardtypeDetailCellImgDiv">
                          <img
                            class="roomItemIcon"
                            src="../../assets/images/add_bed_icon.png"
                          />
                        </div>
                        <div class="roomCardtypeDetailCellContentDiv">
                          <div class="roomItemTitle">Additional Beds:</div>
                          <div class="roomItemDetail" style="width: 50%">
                            {{ item.roominfo.additionalBeds }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div class="hotelDetailPriceTitle3">
                      {{ selectedDays }} nights
                    </div>

                    <div
                      v-if="item.mainprice != 0"
                      style="margin-left: auto; margin-right: auto"
                    >
                      <div class="hotelRoomTablePrice2">
                        {{ currencyName }}
                        {{
                          addZeroes(
                            Math.round(
                              (item.mainprice != undefined
                                ? item.mainprice +
                                  calcTransfer(
                                    item,
                                    searchRoomInfo[searchRoomInfoID]
                                  )
                                : 0) *
                                currency *
                                100
                            ) / 100
                          )
                        }}
                      </div>
                    </div>
                    <div
                      v-else
                      style="
                        margin-left: auto;
                        margin-right: auto;
                        margin-top: 0px;
                      "
                    >
                      <div class="hotelRoomTablePrice2" style="font-size: 18px">
                        Request Basis
                      </div>
                    </div>

                    <div class="hotelDetailPriceTitle6">
                      Taxes & service charges included
                    </div>
                    <div class="hotelDetailPriceTitle6">
                      Roundtrip airport transfers included
                    </div>
                  </div>

                  <div class="hotelViewBtnsDiv">
                    <!-- <div
                      class="roomAmenitiesBtn"
                      @click="gotoRoomAmenities(index)"
                    >
                      Room Amenities
                    </div> -->
                    <div class="hotelViewRatesBtn" @click="showRates(index)">
                      {{ isShowRates[index] ? "HIDE RATES" : "VIEW RATES" }}
                    </div>
                  </div>

                  <a-modal
                    v-model="visibleFloorPlan[index]"
                    title=""
                    :footer="null"
                    :width="'auto'"
                    :dialog-style="{
                      display: 'flex',
                      'align-items': 'center',
                      'max-width': '1080px',
                      top: '0',
                      height: '100%',
                    }"
                  >
                    <room-plan-previewer
                      v-if="visibleFloorPlan[index]"
                      :imageData="item.roominfo.roomfloorplans"
                      :imageIndex="0"
                      @close="showFloorPlan(index, false, item)"
                    />
                  </a-modal>

                  <a-modal
                    v-model="visibleRoomAmenities[index]"
                    title=""
                    :footer="null"
                    :closable="true"
                    :width="$mq === 'lg' || $mq === 'md' ? '800px' : ''"
                  >
                    <div class="roomAmenitiesTitleMain">
                      <div class="roomAmenitiesTitle">Room Amenities</div>
                      <div class="roomAmenitiesTitleSubDiv">
                        <div class="hotelDetailText">
                          <div
                            v-for="cell in item.roominfo.facilitiesInfo !=
                            undefined
                              ? item.roominfo.facilitiesInfo.slice(
                                  0,
                                  Math.ceil(
                                    item.roominfo.facilitiesInfo.length / 2
                                  )
                                )
                              : []"
                            :key="cell"
                          >
                            • {{ cell }}
                          </div>
                        </div>
                        <div class="hotelDetailText">
                          <div
                            v-for="cell in item.roominfo.facilitiesInfo !=
                            undefined
                              ? item.roominfo.facilitiesInfo.slice(
                                  Math.ceil(
                                    item.roominfo.facilitiesInfo.length / 2
                                  )
                                )
                              : []"
                            :key="cell"
                          >
                            • {{ cell }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </a-modal>
                </div>
              </div>

              <div v-if="isShowRates[index]">
                <div
                  v-for="(cellRate, rateindex) in item.promotionprice"
                  :key="cellRate.promotionid"
                >
                  <div class="hotelDetailRoomPriceDiv">
                    <div class="hotelDetailRoomPriceDiv1">
                      <div class="hotelDetailPriceTitle1">
                        {{
                          cellRate.promotioninfo.title != undefined
                            ? cellRate.promotioninfo.title
                            : "Standard"
                        }}
                      </div>
                      <div
                        class="hotelDetailPriceTitle2"
                        @click="showInclusion(cellRate.promotioninfo)"
                      >
                        Inclusions
                      </div>
                      <div
                        class="hotelDetailPriceTitle2"
                        @click="showTermsCondition(cellRate.promotioninfo)"
                      >
                        Terms & Conditions
                      </div>
                    </div>
                    <div class="hotelDetailRoomPriceDiv2">
                      <!-- <div class="hotelDetailPriceTitle3">{{selectedDays}} nights</div> -->
                      <div
                        class="hotelDetailPriceTitle3"
                        style="text-align: left"
                      >
                        {{ selectedDays }} nights,
                        {{ searchRoomInfo[searchRoomInfoID].searchAdultCount }}
                        adults,
                        {{ searchRoomInfo[searchRoomInfoID].searchChildCount }}
                        children
                      </div>
                      <div
                        v-if="item.mainprice != 0"
                        class="hotelDetailPriceTitle4"
                      >
                        {{ currencySign
                        }}{{
                          addZeroes(
                            Math.round(
                              (cellRate.totalprice != undefined
                                ? cellRate.totalprice +
                                  calcTransfer(
                                    item,
                                    searchRoomInfo[searchRoomInfoID]
                                  )
                                : 0) *
                                currency *
                                100
                            ) / 100
                          )
                        }}
                      </div>
                      <div v-else class="hotelDetailPriceTitle4">
                        Request Basis
                      </div>
                      <div
                        class="hotelDetailPriceTitle5"
                        style="cursor: pointer"
                        @click="clickRateBreakdown(index, rateindex)"
                      >
                        Rate breakdown
                      </div>
                    </div>
                    <div class="hotelDetailRoomPriceBtnDiv">
                      <button
                        class="hotelDetailRoomSelect"
                        @click="
                          gotoCheckout(index, rateindex, cellRate.promotioninfo)
                        "
                      >
                        SELECT
                      </button>
                    </div>
                  </div>

                  <div
                    v-show="
                      isShowRateBreakdown[index] != undefined
                        ? isShowRateBreakdown[index][rateindex]
                        : false
                    "
                  >
                    <div class="inspirationLine"></div>
                    <div style="display: flex">
                      <div class="hotelDetailTotalPrice1Div">
                        <div class="hotelDetailTotalPrice1">
                          RATE INCLUDES ({{ currencyName }} currency)
                        </div>
                        <div style="display: flex; margin-bottom: 5px">
                          <div class="hotelDetailTotalPrice2">
                            Roundtrip transfers:
                          </div>
                          <div class="hotelDetailTotalPrice3">
                            {{
                              calcTransfer(
                                item,
                                searchRoomInfo[searchRoomInfoID]
                              ) === 0
                                ? "FREE"
                                : currencySign +
                                  addZeroes(
                                    calcTransfer(
                                      item,
                                      searchRoomInfo[searchRoomInfoID]
                                    )
                                  )
                            }}
                          </div>
                        </div>
                        <div style="display: flex; margin-bottom: 5px">
                          <div class="hotelDetailTotalPrice2">
                            Green tax per person, per day ($12):
                          </div>
                          <div class="hotelDetailTotalPrice3">
                            {{ currencySign
                            }}{{
                              addZeroes(
                                Math.round(
                                  (cellRate.greenTax != undefined
                                    ? cellRate.greenTax
                                    : 0) *
                                    currency *
                                    100
                                ) / 100
                              )
                            }}
                          </div>
                        </div>
                        <div style="display: flex; margin-bottom: 5px">
                          <div class="hotelDetailTotalPrice2">
                            GST & Service charges:
                          </div>
                          <div class="hotelDetailTotalPrice3">
                            {{ currencySign
                            }}{{
                              addZeroes(
                                Math.round(
                                  (cellRate.gstService != undefined
                                    ? cellRate.gstService
                                    : 0) *
                                    currency *
                                    100
                                ) / 100
                              )
                            }}
                          </div>
                        </div>
                        <div style="display: flex; margin-bottom: 5px">
                          <div class="hotelDetailTotalPrice2">
                            Extra adult charge(includes green tax):
                          </div>
                          <div class="hotelDetailTotalPrice3">
                            {{ currencySign
                            }}{{
                              addZeroes(
                                Math.round(
                                  (cellRate.extraAdultCharge != undefined
                                    ? cellRate.extraAdultCharge
                                    : 0) *
                                    currency *
                                    100
                                ) / 100
                              )
                            }}
                          </div>
                        </div>
                        <div style="display: flex; margin-bottom: 5px">
                          <div class="hotelDetailTotalPrice2">
                            Extra child charge(includes green tax):
                          </div>
                          <div class="hotelDetailTotalPrice3">
                            {{ currencySign
                            }}{{
                              addZeroes(
                                Math.round(
                                  (cellRate.extraChildCharge != undefined
                                    ? cellRate.extraChildCharge
                                    : 0) *
                                    currency *
                                    100
                                ) / 100
                              )
                            }}
                          </div>
                        </div>
                        <div
                          v-if="permissionLevel >= 4"
                          style="display: flex; margin-bottom: 10px"
                        >
                          <div
                            class="hotelDetailTotalPrice2"
                            style="color: red"
                          >
                            {{
                              selectedHotel.hoteldatageneral != undefined &&
                              selectedHotel.hoteldatageneral.commission !=
                                undefined
                                ? Math.floor(
                                    selectedHotel.hoteldatageneral.commission *
                                      100
                                  )
                                : 14
                            }}% commission:
                          </div>
                          <div
                            class="hotelDetailTotalPrice3"
                            style="color: red"
                          >
                            {{ currencySign
                            }}{{
                              addZeroes(
                                Math.round(
                                  (cellRate.commission != undefined
                                    ? cellRate.commission
                                    : 0) *
                                    currency *
                                    100
                                ) / 100
                              )
                            }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="inspirationLine"></div>
                </div>

                <a-modal
                  class="InclusionDiv"
                  v-model="visibleInclusion"
                  title=""
                  :footer="null"
                  :closable="true"
                  :width="$mq === 'lg' || $mq === 'md' ? '800px' : ''"
                >
                  <div style="width: 100%; font-family: General Sans">
                    <div class="InclusionSubDivTitle">
                      {{ visibleInclusionTitle }}
                    </div>
                    <div class="InclusionSubDivDetail">{{ inclusionText }}</div>
                  </div>
                </a-modal>
              </div>
            </div>
          </a-list-item>
        </a-list>
      </div>

      <a-modal
        v-model="visibleDetailItem"
        title=""
        :footer="null"
        :closable="true"
      >
        <span v-html="detailItemText"></span>
        <!-- <div style="white-space: pre-wrap;">{{detailItemText}}</div> -->
      </a-modal>
      <a-modal
        class="hotelRoomImageModel"
        v-model="visibleRoomImageViewer"
        title=""
        :footer="null"
        :width="'auto'"
        :dialog-style="{
          display: 'flex',
          'align-items': 'center',
          'max-width': '1080px',
          top: '0',
          height: '100%',
        }"
      >
        <room-image-previewer
          v-if="visibleRoomImageViewer"
          :imageData="roomImageListAll"
          :imageIndex="indexRoomImageViewer"
          @close="showRoomImageViewer(false)"
        />
      </a-modal>
    </div>
  </lazy-component>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import moment from "moment";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import ImagePreviewer from "./image-previewer.vue";
import { common } from "../../helpers/Common";
import { bus } from "../../main";
import RoomImagePreviewer from "./room-image-previewer.vue";
import StarRating from "vue-star-rating";
import RoomPlanPreviewer from "./room-plan-previewer.vue";
import ImageViewer from "./image-viewer.vue";
const imageUrls = [
  {
    url: "https://cdn.vuetifyjs.com/images/john.jpg",
  },
  {
    url: "https://cdn.vuetifyjs.com/images/john.jpg",
  },
  {
    url: "https://cdn.vuetifyjs.com/images/john.jpg",
  },
  {
    url: "https://cdn.vuetifyjs.com/images/john.jpg",
  },
];
export default {
  data() {
    return {
      imageUrls,
      hotelId: "",
      selectedHotel: {},
      hotelImageListAll: [],
      selecteBookingInfo: {},

      visibleImageViewer: false,
      indexImageViewer: 0,
      isShowRoomMore: new Array(),
      isShowRoomTextMore: new Array(),
      visibleFloorPlan: new Array(),
      visibleFloorPlanMobile: new Array(),
      isShowRates: new Array(),
      visibleRoomAmenities: new Array(),
      inclusionText: "",
      visibleInclusionTitle: "",
      visibleInclusion: false,

      visibleRoomImageViewer: false,
      roomImageListAll: [],
      indexRoomImageViewer: 0,

      visibleDetailItem: false,
      detailItemText: "",

      visibleMapViewer: false,
      hotelMapListAll: [],

      visibleSearchCountPicker: false,

      searchRoomCount: 0,
      searchAdultCount: 0,
      searchChildCount: 0,
      searchChildAge: 0,

      visibleRoomList: false,
      visibleRoomListMobile: false,

      currency: 1.0,
      currencyName: "USD",
      currencySign: "$",

      permissionLevel: 1,

      selectedDays: 1,

      showedPromotions: [],
      isShowRateBreakdown: [],

      isRoomTextShow: false,

      sliderStartIndex: [],
    };
  },

  props: {
    roomInfoList: [],
    searchStartDate: Number,
    searchEndDate: Number,
    searchRoomInfo: [],
    searchRoomInfoID: Number,
  },

  components: {
    ImagePreviewer,
    VueSlickCarousel,
    RoomImagePreviewer,
    StarRating,
    RoomPlanPreviewer,
    ImageViewer,
  },
  created() {
    this.currency = this.$store.state.selectedCurrency.rate;
    this.currencyName = this.$store.state.selectedCurrency.name;
    this.currencySign = this.$store.state.selectedCurrency.sign;

    if (this.$store.state.loginUser !== null) {
      const loginType = this.$store.state.loginUser.loginType;
      this.permissionLevel =
        this.$store.state.loginUser[loginType].permissionLevel;
    }
  },
  mounted() {
    const fromDate = new Date(this.searchStartDate);
    const toDate = new Date(this.searchEndDate);

    const diffTime = Math.abs(toDate - fromDate);
    this.selectedDays = Math.round(diffTime / (1000 * 60 * 60 * 24));

    this.initHotel();
  },
  methods: {
    moment,
    addZeroes(num) {
      let textNum = num.toString();
      let decimalResult = Number(textNum).toFixed(
        Math.max(textNum.split(".")[1]?.length, 2) || 2
      );
      let thousand = decimalResult
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return thousand;
    },
    getDays(_second) {
      return Math.floor(_second / (24 * 60 * 60));
    },
    calcTransfer(item, searchCount) {
      const diffTimestampOffset = new Date().getTimezoneOffset() * 60;
      // const transferArray = this.selectedHotel.transfer.filter(
      //   (
      //     obj
      //   ) =>
      //     this.getDays(moment(obj.condition.transferstart).utc().unix()) <=
      //       this.getDays(
      //         moment(this.searchStartDate).utc().unix() - diffTimestampOffset
      //       ) &&
      //     this.getDays(moment(obj.condition.transferend).utc().unix()) >=
      //       this.getDays(
      //         moment(this.searchStartDate).utc().unix() - diffTimestampOffset
      //       )
      // );
      const transferArray = this.selectedHotel.transfer.filter(obj =>
        obj.condition.transferDate.some(date => 
          this.getDays(moment(date.transferstart).utc().unix()) <= 
            this.getDays(moment(this.searchStartDate).utc().unix() - diffTimestampOffset) &&
          this.getDays(moment(date.transferend).utc().unix()) >= 
            this.getDays(moment(this.searchStartDate).utc().unix() - diffTimestampOffset)
        )
      );

      if (transferArray.length > 0) {
        if (
          transferArray[0].price != undefined &&
          transferArray[0].price.transfer != undefined &&
          transferArray[0].price.transfer.length > 0
        ) {
          let peradultprice = 0;
          let perchildprice = 0;
          let perinfantprice = 0;
          transferArray.forEach((array) => {
            array.price.transfer.forEach((item) => {
              if (
                this.selectedDays >= item.min &&
                this.selectedDays <= item.max
              ) {
                peradultprice = item.peradultprice;
                perchildprice = item.perchildprice;
                perinfantprice = item.perinfantprice;
              }
            });
          });
          let childPrice = 0;
          searchCount.searchChildAge.forEach((item) => {
            if (item > transferArray[0].condition.childagerangeto) {
              childPrice += peradultprice;
            } else if (item >= transferArray[0].condition.childagerangefrom) {
              childPrice += perchildprice;
            } else {
              childPrice += perinfantprice;
            }
          });

          const transferPrice =
            peradultprice * searchCount.searchAdultCount + childPrice;
          return transferPrice;
        }
      }
      return 0;
    },
    initHotel: async function () {
      // const _id = this.$route.query.id;
      if (this.roomInfoList.length <= 0) return;
      this.selectedHotel = this.roomInfoList[0].hotelinfo;

      // var tempRoomList = [];
      for (let i = 0; i < this.roomInfoList.length; i++) {
        this.sliderStartIndex.push(0);

        /////// only show two offers: max and standard

        // var tempPromotion = []
        // var setMain = false;
        // var setMax = false;
        // for(let j = 0; j < this.roomInfoList[i].promotionprice.length; j++){
        //     if (!setMain && this.roomInfoList[i].promotionprice[j].totalprice == this.roomInfoList[i].mainprice){
        //         setMain = true;
        //         tempPromotion.push(this.roomInfoList[i].promotionprice[j]);
        //     }
        //     else if (this.roomInfoList[i].promotionprice[j].totalprice == this.roomInfoList[i].maxprice){
        //         if (!setMax){
        //             setMax = true;
        //             tempPromotion.push(this.roomInfoList[i].promotionprice[j]);
        //         }
        //         else if (this.roomInfoList[i].promotionprice[j].promotioninfo.title.toLowerCase().includes('standard')){
        //             tempPromotion.splice(tempPromotion.length - 1, 1);
        //             tempPromotion.push(this.roomInfoList[i].promotionprice[j]);
        //         }
        //     }
        // }
        // this.roomInfoList[i].promotionprice = tempPromotion;
      }

      this.hotelImageListAll = [];
      if (
        this.selectedHotel.hotelimage != undefined &&
        this.selectedHotel.hotelimage != ""
      )
        this.hotelImageListAll.push(this.selectedHotel.hotelimage);
      if (
        this.selectedHotel.gallaryimages != undefined &&
        this.selectedHotel.gallaryimages.length > 0
      )
        this.hotelImageListAll = this.hotelImageListAll.concat(
          this.selectedHotel.gallaryimages
        );
      this.hotelMapListAll = [];
      this.hotelMapListAll.push(this.selectedHotel.hotelmap);
      this.initShowHideOptions();
    },
    initShowHideOptions() {
      this.isShowRoomMore = [];
      this.isShowRoomTextMore = [];
      this.isShowRates = [];
      this.visibleRoomAmenities = [];
      this.visibleFloorPlan = [];
      this.visibleFloorPlanMobile = [];
      for (let i = 0; i < this.roomInfoList.length; i++) {
        this.isShowRoomMore.push(false);
        this.isShowRoomTextMore.push(false);
        this.isShowRates.push(false);
        this.visibleRoomAmenities.push(false);
        this.visibleFloorPlan.push(false);
        this.visibleFloorPlanMobile.push(false);
      }
    },

    clickRateBreakdown(totalIndex, index) {
      if (this.isShowRateBreakdown.length <= totalIndex) {
        for (let i = this.isShowRateBreakdown.length; i <= totalIndex; i++)
          this.isShowRateBreakdown[i] = [];
      }
      if (this.isShowRateBreakdown[totalIndex].length <= index) {
        for (
          let i = this.isShowRateBreakdown[totalIndex].length;
          i <= index;
          i++
        )
          this.isShowRateBreakdown[totalIndex][i] = false;
      }
      var tempInfo = this.isShowRateBreakdown[totalIndex];
      tempInfo.splice(index, 1, !tempInfo[index]);
      this.isShowRateBreakdown.splice(totalIndex, 1, tempInfo);
    },
    showImageViewer(isShow, index) {
      this.visibleImageViewer = isShow && this.hotelImageListAll.length > 0;
      this.indexImageViewer = index;
    },
    showRoomImageViewer(isShow, index, _imageList) {
      if (isShow) {
        this.visibleRoomImageViewer = isShow && _imageList.length > 0;
        this.roomImageListAll = _imageList;
        this.indexRoomImageViewer = index;
      } else this.visibleRoomImageViewer = isShow;
    },
    gotoFactsheet() {
      if (this.selectedHotel.hotelfacesheet != undefined)
        window.open(`${this.selectedHotel.hotelfacesheet.id}`, "_blank");
      // https://drive.google.com/file/d/1GvLzWsAifsC18Je78TgJpNf8Cb40_V23/view?usp=sharing
    },
    gotoResortMap(isShow) {
      this.visibleMapViewer = isShow;
    },

    gotoRoomAmenities(_index) {
      const _curShow = this.visibleRoomAmenities[_index];
      this.visibleRoomAmenities.splice(_index, 1, !_curShow);
    },
    showRoomMore(_index) {
      const _curShow = this.isShowRoomMore[_index];
      this.isShowRoomMore.splice(_index, 1, !_curShow);
    },
    showRoomTextMore(_text) {
      this.visibleDetailItem = true;
      this.detailItemText = _text;
      // const _curShow = this.isShowRoomTextMore[_index];
      // this.isShowRoomTextMore.splice(_index, 1, isShow);
    },
    showFloorPlan(_index, isMobile = false, _item) {
      if (
        _item.roominfo.roomfloorplans != undefined &&
        _item.roominfo.roomfloorplans.length > 0 &&
        _item.roominfo.roomfloorplans[0] != "" &&
        _item.roominfo.roomfloorplans[0].substring(
          _item.roominfo.roomfloorplans[0].length - 4
        ) == ".pdf"
      ) {
        window.open(`${_item.roominfo.roomfloorplans[0]}`, "_blank");
      } else {
        if (!isMobile) {
          const _curShow = this.visibleFloorPlan[_index];
          this.visibleFloorPlan.splice(_index, 1, !_curShow);
        } else {
          const _curShow = this.visibleFloorPlanMobile[_index];
          this.visibleFloorPlanMobile.splice(_index, 1, !_curShow);
        }
      }
    },
    showRates(_index) {
      if (this.$store.state.loginUser !== null) {
        const loginType = this.$store.state.loginUser.loginType;
        this.permissionLevel =
          this.$store.state.loginUser[loginType].permissionLevel;
      }

      const _curShow = this.isShowRates[_index];
      this.isShowRates.splice(_index, 1, !_curShow);
    },
    showInclusion(_info) {
      if (_info != undefined && _info.description != "")
        this.inclusionText = _info.description;
      else this.inclusionText = "";
      this.visibleInclusion = true;
      this.visibleInclusionTitle = "INCLUSIONS";
    },
    showTermsCondition(_info) {
      if (_info != undefined && _info.termsconditions != "")
        this.inclusionText = _info.termsconditions;
      else this.inclusionText = "";
      this.visibleInclusion = true;
      this.visibleInclusionTitle = "TERMS & CONDITIONS";
    },
    gotoCheckout(roomindex, rateindex, promotioninfo) {
      if (!common.getLoginState(this.$store)) {
        this.$message.error(
          "Rates are restricted to VUE members only. Please register or sign-in."
        );
        bus.$emit("showLogin", true);
        return;
      }
      var _data = {
        hotelid: this.roomInfoList[roomindex].hotelid,
        roomAllInfo: this.roomInfoList[roomindex],
        promotionindex: rateindex,
        promotioninfo: promotioninfo,
        searchAdultCount:
          this.searchRoomInfo[this.searchRoomInfoID].searchAdultCount,
        searchChildCount:
          this.searchRoomInfo[this.searchRoomInfoID].searchChildCount,
        searchChildAge:
          this.searchRoomInfo[this.searchRoomInfoID].searchChildAge,
        searchindex: this.searchRoomInfoID,
      };
      this.$emit("selectOneRoomSearch", this.searchRoomInfoID, _data);
    },

    afterPageChange(page, _index) {
      this.sliderStartIndex[_index] = page;
    },
    prevSlide(_index, _length) {
      var aIndex = this.sliderStartIndex[_index] - 1;
      if (aIndex < 0) aIndex = _length - 1;
      this.sliderStartIndex.splice(_index, 1, aIndex);
    },
    nextSlide(_index, _length) {
      var aIndex = this.sliderStartIndex[_index] + 1;
      if (aIndex >= _length) aIndex = 0;
      this.sliderStartIndex.splice(_index, 1, aIndex);
    },
  },
};
</script>
<style scoped>
.sliderImage {
  max-height: 30vh;
  height: 30vh;
  width: 100%;
  background-color: white;
  object-fit: cover;
}

.slideImageHomePreviewer {
  display: block;
  max-width: 1080px;
  object-fit: cover;
}
</style>
