<template>
  <lazy-component class="tabCellMainDiv">
    <div class="tabCellMain">
      <a-list
        v-if="!loading && !filtering && filterResult.length > 0"
        :grid="{ gutter: 16, xs: 1, sm: 1, md: 1, lg: 1, xl: 1, xxl: 1 }"
        :data-source="filterResult"
      >
        <a-list-item slot="renderItem" slot-scope="item, index">
          <div class="tabCellItemParent">
            <div class="tabCellItemImgDiv">
              <img
                class="tabCellItemImg"
                :src="
                  item.info.hotelimage != '' &&
                  item.info.hotelimage != undefined
                    ? `${item.info.hotelimage}`
                    : ''
                "
                alt=""
              />
            </div>
            <div class="tabCellItemContentMainDiv">
              <div class="tabCellItemContentDiv">
                <!-- <div class="resortsItemTitle1" style="padding-left: 10px;">{{item.info.hotelproperty != undefined ? item.info.hotelproperty.bestFor : ''}}</div> -->
                <div class="resortsItemTitle2">{{ item.info.hotelname }}</div>
                <!-- <div class="resortsItemTitle3">
                                    {{item.info.hotelproperty != undefined ? item.info.hotelproperty.location :''}}
                                </div> -->
                <div class="tabCellItemRatingDiv">
                  <StarRating
                    :max-rating="6"
                    :rating="item.info.star"
                    :show-rating="false"
                    :round-start-rating="false"
                    v-bind:star-size="18"
                    :active-on-click="true"
                    :read-only="true"
                    active-color="#FFC400"
                    inactive-color="#E7E7E7"
                  />
                  <a-popover placement="rightTop" class="StarOverDiv">
                    <template slot="content">
                      <div class="resortStarOverDivCell">
                        Stars are on a 6-point scale indicating the level of
                        luxury based on service, amenities, facilities & price.
                        6-stars indicate the highest level of luxury.
                      </div>
                    </template>
                    <img
                      class="tabCellItemRatingImg"
                      src="../../assets/images/starInfo.png"
                      alt=""
                    />
                  </a-popover>
                </div>
                <div class="resortsTaxDes">
                  <p class="resortCard-bestfor">
                    <span>Best For: </span>
                    {{ item.info.hotelproperty.bestFor }}
                  </p>
                  <p class="resortCard-bestfor">
                    <span>Resort Style: </span
                    >{{ getResortStyleStr(item.info.hoteldatarooms.stype) }}
                  </p>
                </div>

                <div class="tabCellItemContentCheckDiv">
                  <img
                    src="@/assets/images/checktrue.png"
                    alt=""
                    width="20px"
                    height="20px"
                  />
                  <div class="resortExplain2">Best Price guarantee</div>
                </div>
                <div class="tabCellItemContentNightDiv">
                  {{ item.days != undefined ? item.days : 7 }} nights from
                </div>

                <div>
                  <div v-if="item.mainprice !== 0">
                    <div class="tabCellItemContentPrice2">
                      {{ currencyName }}
                      {{ addZeroes(Math.round((item.mainprice) * 100) / 100) }}
                    </div>
                  </div>
                  <div v-if="item.mainprice != 0">
                    <!-- <div class="resortsPrice1">{{currencyName}} {{addZeroes(Math.round((item.maxprice != undefined ? item.maxprice * currency : item.info.hoteldatageneral.reqularprice * diffDays * currency) * 100) / 100)}}</div> -->
                    <!-- <div class="tabCellItemContentPrice2">{{currencyName}} {{addZeroes(Math.round((item.mainprice != undefined ? item.mainprice * currency : item.info.hoteldatageneral.reqularprice * item.days * currency) * 100) / 100)}}</div> -->
                  </div>
                  <div v-else style="display: flex">
                    <div class="tabCellItemContentPrice2">Request Basis</div>
                  </div>
                </div>

                <div class="tabCellItemContentNightDiv">
                  Taxes & service charges included
                </div>
                <div class="hotelDetailPriceTitle6">
                  Roundtrip airport transfers included
                </div>

                <div class="hotelViewBtnsDiv">
                  <button
                    class="homeViewResort"
                    @click="gotoResortDetail(index)"
                  >
                    VIEW RESORT
                  </button>
                </div>
              </div>
            </div>
          </div>
        </a-list-item>
      </a-list>
      <!-- <a-skeleton :loading="loading" active></a-skeleton> -->

      <div v-else-if="!loading && !filtering && filterResult.length <= 0">
        <div style="text-align: center; margin-top: 50px">
          <svg
            width="64"
            height="41"
            viewBox="0 0 64 41"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g transform="translate(0 1)" fill="none" fillRule="evenodd">
              <ellipse fill="#F5F5F5" cx="32" cy="33" rx="32" ry="7"></ellipse>
              <g fillRule="nonzero" stroke="#D9D9D9">
                <path
                  d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"
                ></path>
                <path
                  d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z"
                  fill="#FAFAFA"
                ></path>
              </g>
            </g>
          </svg>
        </div>
        <div class="resortEmptyDiv">
          Apologies as we do not have a property that matches your criteria.
          Please select another option or contact our Concierge via chat to
          assist you further.
        </div>
      </div>
      <div v-else class="tabCellInfoEmptyDiv">
        <img
          class="tabCellImageDetail"
          :src="'../images/logo.gif'"
          alt=""
          width="100%"
          height="100%"
          loop="infinite"
        />
      </div>
      <div v-if="!loading && !isLast" style="display: flex">
        <div class="tabCellGetMoreBtn" @click="getMoreList()">Get More</div>
      </div>
    </div>
  </lazy-component>
</template>
<script>
import StarRating from "vue-star-rating";
import moment from "moment";
// import ContactusViewer from './contactus-viewer.vue';
// import InstagramViewer from './instagram-viewer.vue';
export default {
  data() {
    return {
      currency: 1.0,
      currencyName: "USD",
      currencySign: "$",
      diffDays: 0,
    };
  },
  components: {
    StarRating,
  },
  props: {
    filterResult: [],
    // item: Object,
    searchStartDate: String,
    searchEndDate: String,
    searchRoomInfoID: Number,
    searchRoomInfo: [],
    loading: Boolean,
    filtering: Boolean,
    isLast: Boolean,
  },
  created() {
    (this.currency = this.$store.state.selectedCurrency.rate),
      (this.currencyName = this.$store.state.selectedCurrency.name),
      (this.currencySign = this.$store.state.selectedCurrency.sign);

    this.fromdate = new Date(this.searchStartDate);
    this.todate = new Date(this.searchEndDate);

    this.diffTime = Math.abs(this.todate - this.fromdate);
    this.diffDays = Math.round(this.diffTime / (1000 * 60 * 60 * 24));
  },
//   updated() {
//     if (this.filterResult.length !== this.resultLength) {
//       this.fromDate = new Date(this.searchStartDate);
//       this.toDate = new Date(this.searchEndDate);

//       this.diffTime = Math.abs(this.toDate - this.fromDate);
//       this.selectedDays = Math.round(this.diffTime / (1000 * 60 * 60 * 24));

//       this.resultLoaded = false;
//       this.resultLength = this.filterResult.length;
//     }
//     if (this.filterResult.length > 0 && this.resultLoaded === false) {
//       var filterBuf = this.filterResult;
//       for (let index = 0; index < filterBuf.length; index++) {
//         filterBuf[index].mainprice =
//           Math.round(
//             (filterBuf[index].mainprice != undefined
//               ? (filterBuf[index].mainprice +
//                   this.calcTransfer(filterBuf[index], this.searchRoomInfo[0])) *
//                 this.currency
//               : filterBuf[index].info.hoteldatageneral.reqularprice *
//                 filterBuf[index].days *
//                 this.currency) * 100
//           ) / 100;
//       }

//       filterBuf.sort((p1, p2) =>
//         p1.mainprice > p2.mainprice ? 1 : p1.mainprice < p2.mainprice ? -1 : 0
//       );

//       this.resultLoaded = true;
//       this.resultLength = this.filterResult.length;
//       this.filterResult = filterBuf;
//     }
//   },
  mounted(){
        if(this.filterResult.length !== this.resultLength)
        {
            this.fromDate = new Date(this.searchStartDate);
            this.toDate = new Date(this.searchEndDate);
            
            this.diffTime = Math.abs(this.toDate - this.fromDate);
            this.selectedDays = Math.round(this.diffTime / (1000 * 60 * 60 * 24));

            this.resultLoaded = false;
            this.resultLength = this.filterResult.length;
        }

        // if (this.filterResult.length > 0 && this.resultLoaded1 === false) {
        //     var filterBuf = this.filterResult;
        //     for (let index = 0; index < filterBuf.length; index++) {
        //         filterBuf[index].mainprice = (Math.round((filterBuf[index].mainprice != undefined ? (filterBuf[index].mainprice + this.calcTransfer(filterBuf[index], this.searchRoomInfo[0])) * this.currency : filterBuf[index].info.hoteldatageneral.reqularprice * filterBuf[index].days * this.currency) * 100) / 100);
        //     }
            
        //     filterBuf.sort((p1, p2) => (p1.mainprice > p2.mainprice) ? 1 : (p1.mainprice < p2.mainprice) ? -1 : 0);

        //     this.resultLoaded1 = true;
        //     this.resultLength = this.filterResult.length;
        //     this.filterResult = filterBuf;
        // }
    },
  methods: {
    moment,
    addZeroes(num) {
      let textNum = num.toString();
      let decimalResult = Number(textNum).toFixed(
        Math.max(textNum.split(".")[1]?.length, 2) || 2
      );
      let thousand = decimalResult
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return thousand;
    },
    gotoResortDetail(_index) {
        // this.resultLoaded = false;
      this.$emit("seleteOneHotel", _index);
    },

    getMoreList() {
      if (this.isLast) return;
      // this.loading = true;
      this.$emit("getMoreList");
    },

    getResortStyleStr(_array) {
      // const resortStyleList = {

      //     0:'Modern',
      //     1:'Barefoot luxury',
      //     2:'Rustic chic',
      //     3:'Ultra-luxury',
      //     4:'Elegant',
      //     5:'Maldivian & Modern',
      //     6:'Maldivian rustic',

      // }
      // var _arratStr = [];
      // for(let i = 0; i < _array.length; i++){
      //     if (_array[i] in resortStyleList)
      //     _arratStr.push(resortStyleList[_array[i]])
      // }
      return _array.join(" | ");
    },
    getDays(_second) {
      return Math.floor(_second / (24 * 60 * 60));
    },
    calcTransfer(item, searchCount) {
      const diffTimestampOffset = new Date().getTimezoneOffset() * 60;
      // const transferArray = item.info.transfer.filter(
      //   (obj) =>
      //     this.getDays(moment(obj.condition.transferstart).utc().unix()) <=
      //       this.getDays(
      //         moment(this.searchStartDate).utc().unix() - diffTimestampOffset
      //       ) &&
      //     this.getDays(moment(obj.condition.transferend).utc().unix()) >=
      //       this.getDays(
      //         moment(this.searchStartDate).utc().unix() - diffTimestampOffset
      //       )
      // );
      const transferArray = item.info.transfer.filter(obj =>
        obj.condition.transferDate.some(date => 
          this.getDays(moment(date.transferstart).utc().unix()) <= 
            this.getDays(moment(this.searchStartDate).utc().unix() - diffTimestampOffset) &&
          this.getDays(moment(date.transferend).utc().unix()) >= 
            this.getDays(moment(this.searchStartDate).utc().unix() - diffTimestampOffset)
        )
      );

      if (transferArray.length > 0) {
        if (
          transferArray[0].price != undefined &&
          transferArray[0].price.transfer != undefined &&
          transferArray[0].price.transfer.length > 0
        ) {
          let peradultprice = 0;
          let perchildprice = 0;
          // let perinfantprice = 0;
          transferArray.forEach((array) => {
            array.price.transfer.forEach((item) => {
              if (
                this.selectedDays >= item.min &&
                this.selectedDays <= item.max
              ) {
                peradultprice = item.peradultprice;
                perchildprice = item.perchildprice;
                // perinfantprice = item.perinfantprice;
              }
            });
          });

          let childPrice = 0;
          searchCount.searchChildAge.forEach((item) => {
            if (item > transferArray[0].condition.childagerangeto) {
              childPrice += peradultprice;
            } else {
              childPrice += perchildprice;
            }
          });

          const transferPrice =
            peradultprice * searchCount.searchAdultCount + childPrice;
          return transferPrice;
        }
      }
      return 0;
    },
  },
};
</script>
